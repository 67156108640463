<template>
<div>
    <!-- <v-row>
      gallery.length: {{ gallery.length }} // nbGaleryCols: {{ nbGaleryCols }}  <br>
      {{ gallery.length }} / {{ nbGaleryCols }} = {{ gallery.length / nbGaleryCols }} <br>
      nbImgByColumn: {{ nbImgByColumn }} 
    </v-row> -->
  <v-row>
    <v-col cols="12" :sm="12 / nbGaleryCols" v-for="nCol in nbGaleryCols" :key="nCol">
      <template v-for="(item, i) in gallery">
        <v-card elevation="3" :key="i*nCol" v-if="((i < nCol * nbImgByColumn) && (i >= (nCol-1) * nbImgByColumn))">
          <v-img :src="baseUrl() + item" class="mb-5" elevation="0" @click="openDialogImg(i)" style=" cursor:pointer;"/>
        </v-card>
      </template>
    </v-col>

    <v-dialog fullscreen v-model="showDialogImg">
        <v-card color="#1E1E1EF7">
          <v-row class="ma-0 py-0">
            <v-col cols="12" sm="10" md="8" class="mx-auto" style="margin-top:50px;">
              <v-btn x-large icon outlined @click="openDialogImg(lastImgX - 1)" 
                     style="position:fixed; top:50%; left: 5%;" dark>
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <img :src="baseUrl() + dialogImgUrl" contain  
                      max-width="100%" max-height="700"
                      style="border-radius:8px; max-height:800px; max-width:100%;" 
                      class="mx-auto my-5 d-flex"/>
              <v-btn x-large icon outlined @click="openDialogImg(lastImgX + 1)" 
                     style="position:fixed; top:50%; right:5%;" dark>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-toolbar class="px-3 toolbar-popup" style="position:fixed; top:0%;" color="transparent" height="70" elevation="0">
            <v-spacer/>
            <v-btn icon dark outlined @click="showDialogImg = false" >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
    </v-dialog>
  </v-row>
</div>
</template>

<script>
  import core from '../../plugins/core.js'

  export default {
    name: 'GalleryGrid',
    components: {  },
    props: ['gallery', 'nbCols'],
    data: () => ({
      nbGaleryCols: 3, //par défaut 3 colonnes
      showDialogImg: false,
      lastImgX: '',
      dialogImgUrl: ''
    }),
    async mounted(){
      this.init()

      //défilement des images par les fleche du clavier
      window.addEventListener("keyup", e => {
        if(this.showDialogImg){
          if(e.keyCode == 37) this.openDialogImg(this.lastImgX - 1) //left
          if(e.keyCode == 39) this.openDialogImg(this.lastImgX + 1) //right
        }
      });
      
    },
    methods: {
      init(){
         this.nbGaleryCols = 3
        //calcul le nombre de colonne à créer
        //si nombre pair ET moins de 4 photos dans la gallery : seulement 2 colonnes
        if(this.gallery.length % 2 == 0 && this.gallery.length <= 4 ) {
          this.nbGaleryCols = 2
        }
        if(this.gallery.length == 1) this.nbGaleryCols = 1

        if(this.nbCols != null) this.nbGaleryCols = this.nbCols

        //alert(this.gallery.length + " - nbcols : " + this.nbGaleryCols + " - " + this.nbCols )
      },
      openDialogImg(x){
        if(x < 0) x = this.gallery.length -1
        if(x >= this.gallery.length) x = 0

        this.gallery.forEach((img, i)=>{
          if(i == x) this.dialogImgUrl = img
        })
        this.showDialogImg = true
        this.lastImgX = x
      },
      baseUrl(){ return core.baseUrl() }
    },
    computed: {
      nbImgByColumn(){ 
        if(this.gallery.length == 1) return 1
        if(this.gallery.length > 4 && this.gallery.length % 2 != 0) return parseInt((this.gallery.length / this.nbGaleryCols) + 1) 
        
        let nb = parseInt(this.gallery.length / this.nbGaleryCols)
        if(this.gallery.length > (nb * this.nbGaleryCols)) nb++
         return nb
      }
      
    },
    watch: {
      'gallery': { immediate: true, 
          async handler () {
              //alert("reinit gallery")
              this.init()
          }
      },
  }, 
  }
</script>
